import axios from 'axios';
import { notification } from 'antd';


const handleSignUp = async ({ email }) => {
  try {
    const data = {
      user: {
        first_name: email.split('@')?.[0],
        last_name: email.split('@')?.[1],
        email: email,
        // password,
        // password_confirmation: password,
        platform: 'Repricer'
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/users`,
      data,
    );
    const { token, success, message } = response.data || {};

    if (success && token) {
      window.open(`${process.env.REACT_APP_SS_BASE_URL}/reprice/guide?token=${token}`, '_self');
    }

    const notificationObj = {
      top: 65,
      message: 'Sign Up',
      description: message || 'Something Went Wrong!'
    };

    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return response;
  } catch (err) {
    const { errors } = err?.response?.data || {};

    notification.error({
      top: 65,
      message: 'Sign Up',
      description: errors || err.message || 'Something Went Wrong!'
    });
  }
};

export default handleSignUp;
